.content {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  background-size: 100% 100%;
}

.content_page {
  display: flex;
  flex-wrap: nowrap;
}

.rewardbox {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.rewardItem {
  width: 61.625rem; /* 986px converted */
  display: flex;
  align-items: center;
  padding: 0 1.3125rem 0 2.0625rem; /* 21px and 33px converted */
  background: rgba(249, 95, 33, 0.4);
  border: 1px solid transparent;
  position: relative;
  border-radius: 2.75rem; /* 44px converted */
  margin-top: 5.75rem; /* 92px converted */
}

.rewardItem img:nth-of-type(1) {
  width: 4.4375rem; /* 71px converted */
  height: 4.4375rem; /* 71px converted */
}

.rewardItem div {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 1.625rem; /* 26px converted */
  color: #fff;
  line-height: 1.875rem; /* 30px converted */
  font-style: normal;
  text-transform: none;
}

.rewardItem div text {
  color: #f95f21;
}

.rewardItem img:nth-of-type(2) {
  width: 13.1875rem; /* 211px converted */
  height: 7.5rem; /* 90px converted */
  position: absolute;
  right: 2.75rem; /* 44px converted */
  top: -4.75rem; /* 76px converted */
}

.listBox {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rewardList {
  display: flex;
  flex-wrap: nowrap;
}

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(12.5rem); /* 200% converted */
  }
  100% {
    transform: translateX(-7.5rem); /* 120% converted */
  }
}

.videobox {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  position: relative;
  /* background: rgba(0, 0, 0, 0.7); */
}

.videobox .tipGiver {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 62.5rem; /* 1000px converted */
}

.videobox .tipGiver text {
  color: #f75f21;
  font-size: 1.5rem; /* 24px converted */
  line-height: 2.15rem; /* 35px converted */
  font-weight: 700;
}

.videobox .tipGiver text:not(:first-of-type) {
  margin-left: 0.9375rem; /* 15px converted */
}

.videobox .tipGiver_transition {
  transition: top 0.3125s ease; /* 5s converted */
  top: 1.875rem; /* 30px converted */
}

.qrCodeBox {
  width: 7.5375rem; /* 239px converted */
  height: 10.3125rem; /* 341px converted */
  background: #ffffff;
  border-radius: 0.6875rem; /* 19px converted */
  position: absolute;
  z-index: 2;
  bottom: 1.675rem; /* 50px converted */
  right: 1.675rem; /* 50px converted */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrCodeBox text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 700;
  font-size: 1.125rem; /* 34px converted */
  line-height: 1.25rem; /* 40px converted */
  margin-top: 0.rem; /* 8px converted */
}

.qrCodeBox img {
  width: 6.6rem; /* 220px converted */
  height: 6.6rem; /* 220px converted */
  background: #ababab;
  border-radius: 0.6875rem; /* 19px converted */
  margin-top: 0.325rem; /* 10px converted */
}
