.barrage-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.barrage-item {
  position: absolute;
  white-space: nowrap;
  font-size: 1rem; /* 16px converted */
  color: white;
  font-weight: bold;
  animation-name: moveToLeft;
  animation-duration: 10s; /* Duration should be in seconds */
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  padding: 0 0.9375rem 0 1.5rem; /* 15px and 24px converted */
  background: rgba(249, 95, 33, 0.4);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  border-radius: 2.0625rem; /* 33px converted */
}

.barrage-item img:nth-of-type(1) {
  width: 2.625rem; /* 42px converted */
  height: 2.625rem; /* 42px converted */
}

.barrage-item div {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 1.125rem; /* 18px converted */
  color: #fff;
  line-height: 1.375rem; /* 22px converted */
  font-style: normal;
  text-transform: none;
}

.barrage-item div text {
  color: #f95f21;
}

.barrage-item img:nth-of-type(2) {
  width: 9.8125rem; /* 157px converted */
  height: 4.1875rem; /* 67px converted */
  position: absolute;
  right: 2.0625rem; /* 33px converted */
  top: -3.5625rem; /* 57px converted */
}

.medium {
  padding: 0 1.25rem 0 1.9375rem; /* 20px and 31px converted */
  border-radius: 2.75rem; /* 44px converted */
}

.medium img:nth-of-type(1) {
  width: 5.125rem; /* 82px converted */
  height: 5.125rem; /* 82px converted */
}

.medium div {
  font-size: 2.25rem; /* 36px converted */
  line-height: 2.75rem; /* 44px converted */
}

.medium img:nth-of-type(2) {
  width: 13.0625rem; /* 209px converted */
  height: 5.5625rem; /* 89px converted */
  position: absolute;
  right: 2.75rem; /* 44px converted */
  top: -4.75rem; /* 76px converted */
}

.large {
  padding: 0 1.875rem 0 3rem; /* 30px and 48px converted */
  border-radius: 4.125rem; /* 66px converted */
}

.large img:nth-of-type(1) {
  width: 3.4375rem; /* 55px converted */
  height: 3.4375rem; /* 55px converted */
}

.large div {
  font-size: 1.5rem; /* 24px converted */
  line-height: 1.8125rem; /* 29px converted */
}

.large img:nth-of-type(2) {
  width: 19.625rem; /* 314px converted */
  height: 8.375rem; /* 134px converted */
  position: absolute;
  right: 4.125rem; /* 66px converted */
  top: -7.125rem; /* 114px converted */
}

@keyframes moveToLeft {
  from {
    transform: translateX(14rem);
  }
  to {
    transform: translateX(14rem);
  }
}
